import { useEffect } from 'react';
import registrationInvitationStore from '.';
import { findLink } from '../../LinkyLinky';
import { rootResourceStore } from '../../RootResourceStore';

function useRegistrationInvitationStore(uuid: string) {
  useEffect(() => {
    if (registrationInvitationStore.state.kind === 'registration-invitation-store-state-loaded') {
      return;
    }
    findLink('registration-invitation-template', rootResourceStore.links)
      .map((link) => ({ ...link, href: link.href.replace('{uuid}', uuid) }))
      .do(registrationInvitationStore.load);
  }, [uuid, rootResourceStore.links]);
}

export default useRegistrationInvitationStore;
