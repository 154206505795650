import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { boolean, field, number, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import { RegistrationInvitation, RegistrationInvitationResource } from './Types';

interface ExplicitJust<T> {
  kind: 'just';
  value: T;
}

interface ExplicitNothing {
  kind: 'nothing';
}

type ExplicitMaybe<T> = ExplicitJust<T> | ExplicitNothing;

const explicitJustDecoder = <T>(decoder: Decoder<T>): Decoder<ExplicitJust<T>> =>
  succeed({})
    .assign('kind', field('kind', stringLiteral('just')))
    .assign('value', field('value', decoder));
const explicitNothingDecoder = <T>(): Decoder<ExplicitNothing> =>
  succeed({}).assign('kind', field('kind', stringLiteral('nothing')));
const explicitMaybeDecoder = <T>(decoder: Decoder<T>): Decoder<ExplicitMaybe<T>> =>
  oneOf<ExplicitMaybe<T>>([
    explicitJustDecoder<T>(decoder).and<ExplicitMaybe<T>>(identity),
    explicitNothingDecoder<T>().and<ExplicitMaybe<T>>(identity),
  ]);

const registrationTypeDecoder: Decoder<'Auditor' | 'Student'> = oneOf([
  stringLiteral<'Auditor' | 'Student'>('Auditor'),
  stringLiteral<'Auditor' | 'Student'>('Student'),
]);

const invitationStatusDecoder: Decoder<'active' | 'inactive'> = oneOf([
  stringLiteral<'active' | 'inactive'>('active'),
  stringLiteral<'active' | 'inactive'>('inactive'),
]);

const communicationPreferenceDecoder: Decoder<'Mute' | 'Send'> = oneOf([
  stringLiteral<'Mute' | 'Send'>('Mute'),
  stringLiteral<'Mute' | 'Send'>('Send'),
]);

const sourceDecoder: Decoder<
  | 'direct-enrollment'
  | 'open-enrollment'
  | 'shared-open-enrollment'
  | 'program-family-shared-open-enrollment'
> = oneOf([
  stringLiteral<
    | 'direct-enrollment'
    | 'open-enrollment'
    | 'shared-open-enrollment'
    | 'program-family-shared-open-enrollment'
  >('direct-enrollment'),
  stringLiteral<
    | 'direct-enrollment'
    | 'open-enrollment'
    | 'shared-open-enrollment'
    | 'program-family-shared-open-enrollment'
  >('open-enrollment'),
  stringLiteral<
    | 'direct-enrollment'
    | 'open-enrollment'
    | 'shared-open-enrollment'
    | 'program-family-shared-open-enrollment'
  >('shared-open-enrollment'),
  stringLiteral<
    | 'direct-enrollment'
    | 'open-enrollment'
    | 'shared-open-enrollment'
    | 'program-family-shared-open-enrollment'
  >('program-family-shared-open-enrollment'),
]);

const registrationInvitationPayloadDecoder: Decoder<RegistrationInvitation> = succeed({})
  .assign('id', field('id', number))
  .assign('guid', field('guid', string))
  .assign('use_case_id', field('use_case_id', number))
  .assign('program_family_id', field('program_family_id', explicitMaybeDecoder(number)))
  .assign('registration_type', field('registration_type', registrationTypeDecoder))
  .assign('invitation_status', field('invitation_status', invitationStatusDecoder))
  .assign(
    'communication_preference',
    field('communication_preference', communicationPreferenceDecoder),
  )
  .assign('source', field('source', sourceDecoder))
  .assign('auto_enroll_program_id', field('auto_enroll_program_id', explicitMaybeDecoder(number)))
  .assign('allow_discovery_portal_filters', field('allow_discovery_portal_filters', boolean));

export const registrationInvitationDecoder: Decoder<RegistrationInvitationResource> =
  resourceDecoder(registrationInvitationPayloadDecoder);
