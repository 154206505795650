import { observer } from 'mobx-react';
import { DropdownFilter } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/CommonFilter';
import { allCompetencyOptions } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/CompetencyFilter';
import {
  allDurationOptions,
  DropdownDurationFilter,
} from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/DurationFilter';
import { allLanguageOptions } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/LanguageFilter';
import { allSchoolPartnerOptions } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SchoolPartnerFilter';
import experienceFiltersStore from './FiltersStore';
import useFilterStores from './UseFilterStores';

function InlineFilters() {
  const { filtersAreLoaded } = useFilterStores();

  if (!filtersAreLoaded(experienceFiltersStore)) {
    return <></>;
  }

  const {
    filters,
    languageFilterStore,
    durationFilterStore,
    schoolPartnerFilterStore,
    competencyFilterStore,
  } = experienceFiltersStore.state;

  return (
    <div className="flex basis-7/12 gap-2">
      <DropdownFilter
        label="Competency"
        store={competencyFilterStore}
        filterOptions={allCompetencyOptions(filters)}
      />
      <DropdownDurationFilter
        store={durationFilterStore}
        filterOptions={allDurationOptions(filters)}
      />
      <DropdownFilter
        label="Partner"
        store={schoolPartnerFilterStore}
        filterOptions={allSchoolPartnerOptions(filters)}
      />
      <DropdownFilter
        label="Language"
        store={languageFilterStore}
        filterOptions={allLanguageOptions(filters)}
      />
    </div>
  );
}

export default observer(InlineFilters);
