import { observer } from 'mobx-react';
import { useMobileFilterSwitcher } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/hooks';
import InlineFilters from './InlineFilters';
import SidebarFilters from './SidebarFilters';

function Filters() {
  return (
    <div className="mx-auto box-border flex gap-x-2 px-6 pb-8 pt-0 xl:container md:flex-wrap md:gap-x-0 md:px-16">
      <_Filters />
    </div>
  );
}

const _Filters = observer(() => {
  const mode = useMobileFilterSwitcher();

  return mode === 'desktop' ? <InlineFilters /> : <SidebarFilters />;
});

export default observer(Filters);
