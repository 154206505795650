import { observer } from 'mobx-react';
import useCaseStore from '../../../RegistrationInvitation/UseCaseStore';
import useUseCaseStore from '../../../RegistrationInvitation/UseCaseStore/UseUseCaseStore';
import Loader from '../../../components/Loader';
import RegistrationUserWorkflow from '../RegistrationUserWorkflow';

function UseCaseView() {
  useUseCaseStore();

  switch (useCaseStore.state.kind) {
    case 'use-case-store-state-initialized':
    case 'use-case-store-state-loading':
      return <Loader delay={1000} />;
    case 'use-case-store-state-loaded':
      return <RegistrationUserWorkflow />;
    case 'use-case-store-state-error':
      return <div>Error: ${String(useCaseStore.state.error)}</div>;
  }
}

export default observer(UseCaseView);
