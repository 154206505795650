import { observer } from 'mobx-react';
import Loader from '../../../components/Loader';
import { currentUserStore } from '../../../CurrentUser/Store';
import ThemeContext, { currentTheme } from '../../../Themes/ThemeContext';
import RegistrationAuth from '../../RegistrationAuth';
import RegistrationAutoLaunchable from '../RegistrationAutoLaunchable';
import RegistrationEnrollment from '../RegistrationEnrollment';

function RegistrationUserWorkflow() {
  switch (currentUserStore.state.kind) {
    case 'waiting':
    case 'loading':
    case 'logging-out':
      return <Loader delay={0} />;
    case 'anonymous':
    case 'errored':
      return (
        <ThemeContext.Provider value={currentTheme()}>
          <RegistrationAuth />
        </ThemeContext.Provider>
      );
    case 'ready':
    case 'refreshing':
      return (
        <>
          <RegistrationAutoLaunchable />
          <RegistrationEnrollment />
        </>
      );
  }
}

export default observer(RegistrationUserWorkflow);
