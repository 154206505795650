import { observer } from 'mobx-react';
import { getBackgroundImage } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection';
import FilterSection from './FilterSection';
import Header from './Header';
import WelcomeMessage from './WelcomeMessage';

function ExperienceSelectionView() {
  return (
    <div
      style={getBackgroundImage()}
      className="bg-chevrons flex flex-grow flex-col bg-[length:100%] bg-repeat-y"
    >
      <Header />
      <WelcomeMessage />
      <FilterSection />
    </div>
  );
}

export default observer(ExperienceSelectionView);
